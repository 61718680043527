import { inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { SnackbarType } from '../models/snackbar.model';

@Injectable({
	providedIn: 'root'
})
export class SnackbarService {
	readonly SNACKBAR_DEFAULT_DURATION = 3000; // in milliseconds
	readonly SNACKBAR_DEFAULT_ACTION = 'Got it';
	readonly SNACKBAR_DEFAULT_HORIZONTAL_POSITION = 'center';
	readonly SNACKBAR_DEFAULT_VERTICAL_POSITION = 'bottom';
	readonly #defaultConfig: MatSnackBarConfig = {
		verticalPosition: this.SNACKBAR_DEFAULT_VERTICAL_POSITION,
		horizontalPosition: this.SNACKBAR_DEFAULT_HORIZONTAL_POSITION,
		panelClass: ['snackbar-panel'],
		duration: this.SNACKBAR_DEFAULT_DURATION,
		// viewContainerRef: this.container  // To Do : Inspect how to attach a parent container
	};

	// Services declaration
	readonly #snackBar = inject(MatSnackBar);

	openError(message: string, action = 'Dismiss', config: MatSnackBarConfig = {}): void {
		this.openSnackBar(message, SnackbarType.error, config, action);
	}

	openInfo(message: string, action = 'Info', config: MatSnackBarConfig = {}): void {
		this.openSnackBar(message, SnackbarType.info, config, action);
	}

	openSnackBar(message: string, type: SnackbarType = SnackbarType.info, config: MatSnackBarConfig = {}, action = this.SNACKBAR_DEFAULT_ACTION, showDismiss = false): MatSnackBarRef<SnackbarComponent> {
		return this.#snackBar.openFromComponent(SnackbarComponent, {
			...this.#defaultConfig,
			...config,
			panelClass: type,
			data: {
				message,
				type,
				action,
				showDismiss
			}
		});
	}

	openLoadBar(message = 'Updating'): void {
		this.openSnackBar(message + '...', SnackbarType.loader, { duration: 0 });
	}

	dismissSnackBar(): void {
		this.#snackBar.dismiss();
	}
}
