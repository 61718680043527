<div
	class="sl-snackbar-wrapper sl-width-100 sl-flexbox-row sl-flexbox-align-center sl-flexbox-justify-space-between sl-flexbox-gap-8">
	<div class="sl-snackbar-content">
		<div
			*ngIf="this.data.type === SnackbarType.loader"
			class="sl-snackbar-loader">
			<mat-spinner color="red" diameter="18"></mat-spinner>
		</div>
		{{ data.message }}
	</div>
	<button
		mat-button
		type="button"
		class="sl-snackbar-action"
		*ngIf="!!this.data.action && this.data.type !== SnackbarType.loader"
		(click)="action()">
		{{ data.action }}
	</button>
	<mat-icon
		*ngIf="this.data.showDismiss"
		class="material-symbols-outlined clickable"
		(click)="dismiss()">
		close
	</mat-icon>
</div>
